<template>
  <div class="user">
    <div class="block-quote">
      <el-form :inline="true" :model="form" ref="form">
        <el-form-item label="分支机构" prop="team">
          <el-select v-model="team" placeholder="请选择" style="width: 150px">
            <el-option v-for="item in branchList" :key="item.id" :label="item.area" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" prop="sta">
          <el-select
            v-model="sta"
            placeholder="请选择"
            style="width: 150px"
          >
            <el-option label="全部状态" value=""></el-option>
            <el-option label="待提现" value="10"></el-option>
            <el-option label="提现通过" value="20"></el-option>
            <el-option label="提现拒绝" value="30"></el-option>
          </el-select>
        </el-form-item>
       <el-form-item label="提现人ID" prop="buy_uid">
          <el-input
            style="width: 180px"
            v-model="buy_uid"
            clearable
            placeholder="请输入提现人id"
          ></el-input>
        </el-form-item>
        <el-form-item label="租赁号" prop="buy_name">
          <el-input
            style="width: 180px"
            v-model="buy_name"
            clearable
            placeholder="请输入租赁号"
          ></el-input>
        </el-form-item>
        <el-form-item label="时间" prop="time">
          <el-date-picker
            v-model="form.time"
            type="daterange"
            value-format="yyyy-MM-dd HH:mm:ss"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item style="float: right">
          <el-button
            v-if="checkPermission('usersearch')"
            type="primary"
            icon="el-icon-search"
            @click="searchinfo"
            >搜索</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <!-- <el-button type="primary" @click="open()" style="margin-bottom: 10px"
      >导出</el-button
    > -->
    <page-table
      ref="dataTable"
      :data="branchStaList"
      @changeCurrentPage="changeCurrent"
    >
      <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="buy_uid" label="提现人ID" align="center">
      </el-table-column>
      <el-table-column prop="buy_name" label="租赁号" align="center">
      </el-table-column>
      <el-table-column prop="buy_price" label="提现价格" align="center">
      </el-table-column>
      <el-table-column prop="branch" label="分支机构地点" align="center">
      </el-table-column>
      <el-table-column prop="team_name" label="分支机构经理人" align="center">
      </el-table-column>
      <el-table-column prop="buy_time" label="提现时间" align="center">
      </el-table-column>

      <!-- <el-table-column label="处理状态" align="center">
        <template slot-scope="scope">
          <el-tag type="warning" effect="dark" v-if="scope.row.ex_status == 0"
            >待审核
          </el-tag>
          <el-tag type="success" effect="dark" v-if="scope.row.ex_status == 1"
            >已通过</el-tag
          >
          <el-tag type="danger" effect="dark" v-if="scope.row.ex_status == 2"
            >已拒绝</el-tag
          >
        </template>
      </el-table-column> -->
      <el-table-column prop="sta" label="审核状态" align="center">
        <template slot-scope="scope">
          <span style="color:#409eff" v-if="scope.row.sta == 10">待提现</span>
          <span style="color:#8fd16f" v-if="scope.row.sta == 20">提现通过</span>
          <span style="color: #f78989" v-if="scope.row.sta == 30">提现拒绝</span>
        </template>
      </el-table-column>
       <el-table-column prop="sta_time" label="审核时间" align="center">
      </el-table-column>
      
      <el-table-column label="操作" width="193" align="center">
        <template slot-scope="scope">
          <el-button
            @click="set(scope.row)"
            type="danger"
            v-if="scope.row.sta ==10"
            >处理</el-button
          >
          <el-button type="success" size="small" v-if="scope.row.sta == 20" plain disabled
            >已通过</el-button
          >
          <el-button type="danger" size="small" v-if="scope.row.sta == 30" plain disabled
            >已拒绝</el-button
          >
        </template>
      </el-table-column>
    </page-table>
    <el-dialog
      title="审核"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form label-width="auto">
        <el-row :gutter="20">
          <el-col :span="24">
            <el-form-item label="是否允许该条信息审核通过" prop="role_name">
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="submitForm(30)">拒绝</el-button>
        <el-button type="primary" @click="submitForm(20)">通过</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { payList,branchList,payListSta } from "@/request/api";
import { checkPermission } from "@/utils/permissions";

import pageTable from "@/components/pageTable.vue";

export default {
  components: {
    pageTable,
  },
  data() {
    return {
      sta: "",
      buy_uid:'',
      form: {
        time: [],
      },
      username: "",
      team:"",
      buy_name:"",
      branchList:[],//分支机构选择框
      branchStaList:[],//分支机构审核表格
      dialogVisible: false,
      dialogVisible1: false,
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
      tableSelectList: [], // 多选列表
    };
  },
  watch: {
    "form.time"(newVal) {
      if (newVal == null) {
        this.form.time = [];
      }
    },
  },
  created() {
    this.getUserList(); //获取用户列表
    this.getBranch()
  },
  mounted() {},
  computed: {},
  methods: {
     //获取分支机构
    getBranch(){
      let params={
        token:sessionStorage.getItem("token")
      }
      branchList(params).then((res)=>{
            // console.log(res,'1111111111111111')
            this.branchList=res.data.data
      })
    },
    submitForm(status) {
      let params = {
        token: sessionStorage.getItem("token"),
        sta: status,
        id: this.id,
      };
      payListSta(params).then((res) => {
        console.log(res,'555555')
        if (res.data.code == 200) {
          this.$message.success("操作成功");
          this.dialogVisible = false;
          this.getUserList();
        } else {
          this.$message(res.data.msg);
          this.dialogVisible = false;
          this.getUserList();
        }
      });
    },
    close() {
      this.dialogVisible = false;
    },
    set(row) {
      this.dialogVisible = true;
      this.id = row.id;
      this.types = row.type;
    },
    open() {},
    checkPermission,
    // 切换分页
    changeCurrent(page, size) {
      this.page.currentPage = page;
      this.page.pageSize = size;
      this.getUserList();
    },
    
    searchinfo() {
      this.page.currentPage=1
      this.getUserList()
    },
    getUserList() {
      let token = sessionStorage.getItem("token");
      this.token = token;
      let params = {
        page: this.page.currentPage,
        limit: this.page.pageSize,
        token: sessionStorage.getItem("token"),
        team: this.team,
        sta: this.sta,
        buy_uid:this.buy_uid,
        time1: this.form.time[0],
        time2: this.form.time[1],
      };
      payList(params).then((res) => {
        console.log(res,6666666666666);
        this.page.total = res.data.count;
        this.branchStaList=res.data.data
        this.$refs.dataTable.setPageInfo({
          total: this.page.total,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
